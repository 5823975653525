<template>
    <div class="pagebox">
        <!-- 顶部 -->
        <PHeader></PHeader>

        <!-- 综合测评 -->
        <div class="testbox">
            <div class="testcon">
                <img src="../../../../src/assets/images/result-head.jpg" alt="" class="img" />
                <div class="back1"></div>
                <div class="back2"></div>
                <div class="back3"></div>
                <div class="back4"></div>
                <div class="back5"></div>
                <div class="back6"></div>
                <div class="back7"></div>
                <div class="title">专业定位综合测评</div>
                <div class="testleft">
                    <div class="t2">兴趣维度</div>
                    <div class="t2">性格维度</div>
                    <div class="t2">能力维度</div>
                    <div class="t2">学科维度</div>
                    <div class="t2">价值观维度</div>
                    <div class="t2">专业方向推荐</div>
                </div>
                <div class="testright">
                    <span>本测试依据霍兰德SDS职业兴趣测试模型，按照2015版《中华人民共和国职业分类大典》的职业分类，结合新高考条件和我国高中生现实情况研究而成，仅供考生和家长参考。</span><br/><br/>
                    <span>霍兰德职业适应性测验(The Self-Directed Search，简称SDS)由美国著名职业指导专家J.霍兰德（Holland）编制。在几十年间经过一百多次大规模的实验研究，形成了人格类型与职业类型的学说和测验。 霍兰德在其一系列关于人格与职业关系的假设的基础上，提出了六种基本的职业类型，即：实际型、研究型（调研型）、艺术型、社会型、企业型（事业型）、传统型（常规型）。该测验能帮助被试者发现和确定自己的职业兴趣和能力专长, 从而科学地做出求职择业。</span><br/><br/>
                    <span>霍兰德职业兴趣SDS测试一般是适合于高中生，通过此测试可以让高中生确定自己的兴趣爱好，给大学的专业选择提供参考。</span><br/><br/>
                    <span>人格和职业有着密切的关系，不同职业对从业者的人格特征的要求是有差距的，如果通过科学的测试，可以预知自己的人格特征，这有助于选择适合于个人发展的职业，进行职业倾向测试，可以帮助您作一次简单的人格自评，从而更加清楚自己的人格特征更适合从事哪方面的工作。</span><br/><br/>
                    <span>需要指出的是，志愿选择会受各种因素影响，例如录取分数、招生数量、就业前景、发展趋势等，测试结果不代表你只能选择测试分析的专业，而应根据个人自身情况综合考虑。</span>
                </div>
                <div class="clear"></div>
                <div class="btn" @click.prevent="startTest()">开始测评</div>
            </div>
            
        </div>
        <!-- 底部 -->
        <PFooter></PFooter>
    </div>
</template>
<script>
import PHeader from "@/components/Header.vue";
import PFooter from "@/components/Footer.vue";
export default{
    components:{
        PHeader,
        PFooter
    },
    methods:{
        startTest(){
            this.$store.dispatch('user/judgeLoginStatus').then(flag => {
                if (flag) {
                    this.$router.push('/allround-answer');
                }
                else {
                    this.$message.info('请先登录！');
                    this.$router.push('/login');
                }
            })
        }
    }
}
</script>
<style lang="less" scoped>
.testbox{
    max-width: 1420px;
    margin: auto;
    .testcon{
        margin: 20px 20px 30px;
        padding-top: 30px;
        padding-bottom: 60px;
        position: relative;
        background-color: #fff;
        overflow: hidden;
        .title{
            color: rgba(var(--themecolor),0.85);
            font-size: 36px;
            font-weight: bold;
            letter-spacing: 5px;
            text-align: center;
            margin-bottom: 30px;
        }
        .img{
            display: block;
            position: absolute;
            left: 0px;
            bottom: 0px;
            z-index: 0;
            transform: rotate(180deg);
            opacity: 0.5;
        }
        .back1{
            position: absolute;
            left: -75px;
            top: -75px;
            width: 150px;
            height: 150px;
            border-radius: 100px;
            background-color: rgba(var(--themecolor),0.13);
            z-index: 1;
        }
        .back2{
            position: absolute;
            right: -100px;
            bottom: -100px;
            width: 200px;
            height: 200px;
            border-radius: 100px;
            background-color: rgba(var(--themecolor),0.15);
            z-index: 1;
        }
        .back3{
            position: absolute;
            right: -40px;
            top: -40px;
            width: 80px;
            height: 80px;
            border-radius: 100px;
            background-color: rgba(var(--themecolor),0.12);
            z-index: 1;
        }
        .back4{
            position: absolute;
            left: -45px;
            top: 50px;
            width: 80px;
            height: 80px;
            border-radius: 100px;
            background-color: rgba(var(--themecolor),0.1);
            z-index: 1;
        }
        .back5{
            position: absolute;
            right: -35px;
            bottom: 80px;
            width: 60px;
            height: 60px;
            border-radius: 100px;
            background-color: rgba(var(--themecolor),0.13);
            z-index: 1;
        }
        .back6{
            position: absolute;
            right: 70px;
            bottom: -60px;
            width: 100px;
            height: 100px;
            border-radius: 100px;
            background-color: rgba(var(--themecolor),0.12);
            z-index: 1;
        }
        .back7{
            position: absolute;
            right: 150px;
            bottom: -35px;
            width: 50px;
            height: 50px;
            border-radius: 100px;
            background-color: rgba(var(--themecolor),0.1);
            z-index: 1;
        }
        .testleft{
            width: 350px;
            float: left;
            margin-left: 30px;
            text-align: center;
            position: relative;
            z-index: 2;
            .t2{
                color: rgba(var(--themecolor),1);
                font-size: 22px;
                margin-bottom: 30px;
                line-height: 46px;
                background-color: rgba(var(--themecolor),0.2);
                border-radius: 30px;
            }
        }
        .testright{
            width:calc(100% - 580px);
            float: right;
            margin-right: 130px;
            color: #666;
            font-size: 14px;
            line-height: 26px;
            margin-top: 3px;
            position: relative;
            z-index: 2;
        }
        .btn{
            width: 200px;
            height: 50px;
            line-height: 50px;
            text-align: center;
            color: #fff;
            font-size: 16px;
            background-color: rgba(var(--themecolor),0.85);
            margin: 30px auto 0px;
            transition: all 0.2s;
            -webkit-transition: all 0.2s;
            position: relative;
            z-index: 2;
            border-radius: 5px;
        }
        .btn:hover{
            cursor: pointer;
            background-color: rgba(var(--themecolor),1);
        }
    }
}
</style>